<template>
  <!--    <Alert :type="`info`">Welkom in het nieuwe "Intranet" Intranet V3</Alert>-->
  <d-container fluid>
    <pageTitle title="Intranet V3" subtitle="kwaliteit is geen daad het is een gewoonte"/>
    <d-row>
      <vCard :col="12">
        <p>Dit is de test versie van het nieuwe "Intranet", kom je fouten tegen, gelieve deze te melden bij de ICT</p>
      </vCard>
    </d-row>
  </d-container>
</template>

<script>
// import Alert from '@/components/Alert'

export default {
  name: 'dashboardOverview',
  menu: [
    {
      title: 'Home',
      to: {
        route: 'home'
      },
      htmlBefore: '<i class="material-icons">home</i>'
    },
    {
      title: 'Inkoop',
      roles: ["ADMIN", "SALES"],
      to: {
        route: 'sales'
      },
      htmlBefore: '<i class="material-icons">add_shopping_cart</i>'
    },
    {
      title: 'Inname',
      roles: ["ADMIN", "INTAKE"],
      to: {
        route: 'intake'
      },
      htmlBefore: '<i class="material-icons">engineering</i>'
    },
    {
      title: 'Profiel',
      to: {
        route: 'profile'
      },
      htmlBefore: '<i class="material-icons">person</i>'
    }
  ],
  computed: {
    dashboards: {
      get() {
        return [
          'inkoop',
          'inname',
          'ict',
          'autoschadeland'
        ]
      }
    },
  }
}
</script>
